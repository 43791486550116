import {CopyBlock, dracula} from "react-code-blocks";

function Code({children}) {
    return(
        <CopyBlock
            customStyle={{
                padding: ".75rem",
                overflow: 'auto',
                maxHeight: '250px',
                marginTop: '1rem'
            }}
            text={children.props.children}
            language={children.props.className.substring(5)}
            showLineNumbers={true}
            theme={dracula}
        />
    )
}

export default Code;