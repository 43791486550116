import {Button, Card} from '@rewind-ui/core';
import './Blog.css'
import React, {useEffect, useState} from "react";
import NotFound from "./NotFound";

function Blog() {
    const [data, setData] = useState(null);
    const [summaries, setSummaries] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('./blog/blog.json');
                const result = await response.json();
                setData(result);

                const summaryPromises = Object.entries(result).map(async ([key, item]) => {
                    const summaryPath = `./blog/${item.folder}/summary.txt`;
                    try {
                        const summaryResponse = await fetch(summaryPath);
                        if (!summaryResponse.ok) {
                            throw new Error('Network response was not ok');
                        }
                        const summaryText = await summaryResponse.text();
                        return { [key]: summaryText };
                    } catch (error) {
                        console.error(`Error fetching the summary for folder ${item.folder}:`, error);
                        return { [key]: 'Unable to find summary' };
                    }
                });

                const summariesArray = await Promise.all(summaryPromises);
                const summariesObject = summariesArray.reduce((acc, curr) => ({ ...acc, ...curr }), {});
                setSummaries(summariesObject);
                console.log(summaries);
            } catch (error) {
                console.error('Error fetching the blog data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (data) {
        return (
            <div className={"blog"}>
                    {Object.entries(data).map(([key, item]) => (
                        <Card className={"article-card"}>
                            <Card.Header anchor={key} id={key} className="font-medium">{item.titles[item.available_languages[0]]}</Card.Header>
                            <Card.Body>{summaries[key]}</Card.Body>
                            <Card.Footer className={"flex center"}>
                                <Button variant={"primary"} as={"a"} href={`/${item.id}`} color={"purple"}>En savoir plus</Button>
                            </Card.Footer>
                        </Card>
                    ))}
            </div>
        );
    } else {
        console.log(data)
        return <NotFound/>;
    }
}

export default Blog;