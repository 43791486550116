import "./QuickNav.css"
import {useEffect, useState} from "react";

function QuickNav({currentLanguage}){

    const [headers, setHeaders] = useState([]);
    const [activeHeader, setActiveHeader] = useState(null);

    useEffect(() => {
        const headerElements = document.querySelectorAll('h2, h3');
        const headersWithIds = Array.from(headerElements).map((header, index) => {
            if (!header.id) {
                header.id = `header-${index}`;
            }
            return header;
        });
        setHeaders(headersWithIds);
    }, [currentLanguage]);

    useEffect(() => {
        const handleScroll = () => {
            let activeId = headers[0].id;
            for (let i = 0; i < headers.length; i++) {
                const rect = headers[i].getBoundingClientRect();
                let rectMargin = parseFloat(window.getComputedStyle(headers[i]).marginTop)
                if (rect.top - rectMargin < 20) {
                    activeId = headers[i].id;
                }
            }

            setActiveHeader(activeId);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headers]);



    return (
        <div className="quickNav">
            <ul>
                {headers.map((header, index) => (
                    <li key={index} className={header.id === activeHeader ? `${header.tagName} active` : `${header.tagName}`}>
                        <a href={`#${header.id}`}>{header.textContent}</a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default QuickNav