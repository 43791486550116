import "./Article.css"
import React, {useEffect, useState} from 'react';
import Markdown from "markdown-to-jsx";
import {Card, Select, Table} from "@rewind-ui/core";
import Code from "./Code";
import QuickNav from "./QuickNav";

function ArticleImage({src, alt, title, folder}) {
    return (
        <Card className={"imageContainer"}>
            <Card.Image
                src={`blog/${folder}/${src}`}
                caption={alt}
                title={alt}
                mode={"dark"}
            />
        </Card>
    )
}

function ArticleHeader2({children}) {
    return (
        <h2 id={children}>
            <a href={`#${children}`}>{children}</a>
        </h2>
    )
}

function ArticleHeader3({children}) {
    return (
        <h3 id={children}>
            <a href={`#${children}`}>{children}</a>
        </h3>
    )
}

function ArticleLink({children, href}) {
    return (
        <a href={href} target={href.charAt(0) === "#" ? "_self" : "_blank"}>
            {children}
        </a>
    )
}

function CustomTable({ children }) {
    const replaceTableElements = (child) => {
        if (!React.isValidElement(child)) {
            return child;
        }

        // Vérifie le type de l'enfant et le remplace par l'élément
        switch (child.type) {
            case 'thead':
                return <Table.Thead {...child.props}>{React.Children.map(child.props.children, replaceTableElements)}</Table.Thead>;
            case 'tr':
                return <Table.Tr {...child.props}>{React.Children.map(child.props.children, replaceTableElements)}</Table.Tr>;
            case 'th':
                return <Table.Th {...child.props}>{child.props.children}</Table.Th>;
            case 'td':
                return <Table.Td {...child.props}>{child.props.children}</Table.Td>;
            case 'tbody':
                return <Table.Tbody {...child.props}>{React.Children.map(child.props.children, replaceTableElements)}</Table.Tbody>;
            default:
                // Si l'élément contient d'autres enfants, on applique la fonction récursivement
                if (child.props && child.props.children) {
                    return React.cloneElement(child, {
                        children: React.Children.map(child.props.children, replaceTableElements),
                    });
                }
                return child;
        }
    };

    return <Table style={{marginTop:"1rem"}}>{React.Children.map(children, replaceTableElements)}</Table>;
}

const Article = ({data}) => {
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentLanguage, setCurrentLanguage] = useState(data.available_languages[0])

    useEffect(() => {
        document.documentElement.lang = currentLanguage;
    }, [currentLanguage]);

    useEffect(() => {
        document.title = data.titles[currentLanguage];
    }, [currentLanguage, data.titles]);

    useEffect(() => {
        const fetchContent = async () => {
            const newContent = {};
            try {
                for (const lang of data.available_languages) {
                    const response = await fetch(`./blog/${data.folder}/${data.id}.${lang}.md`);
                    if (!response.ok) {
                        throw new Error(`Failed to fetch content for language: ${lang}`);
                    }
                    newContent[lang] = await response.text();
                }
                setContent(newContent);
            } catch (error) {
                console.error('Error fetching the article content:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchContent();
    }, [data]);

    if (loading) {
        return <div>Loading...</div>;
    }


    return (
        <div className={"article"}>
            <div className={"firstColumn"}></div>
            <div className={"secondColumn"}>
                <Select onChange={(e) => setCurrentLanguage(e.target.value)}>
                    {data.available_languages.map((lang) => (
                        <option key={lang} value={lang}>
                            {lang}
                        </option>
                    ))}
                </Select>
                <Markdown
                    className={"content"}
                    options={{
                        overrides: {
                            pre: Code,
                            img: {
                                component: ArticleImage,
                                props: {
                                    folder: data.folder
                                }
                            },
                            a: ArticleLink,
                            h2: ArticleHeader2,
                            h3: ArticleHeader3,
                            table: CustomTable
                        }
                    }}
                >
                    {content[currentLanguage]}
                </Markdown>
            </div>
            <QuickNav currentLanguage={currentLanguage}/>
        </div>
    );
};

export default Article;
