import React, { useState, useEffect } from 'react';
import Article from './Article';
import NotFound from './NotFound';
import {useParams} from "react-router-dom";

const ArticleFinder = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('./blog/blog.json');
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching the blog data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (data && data[id]) {
        return <Article data={data[id]} />;
    } else {
        return <NotFound/>;
    }
};

export default ArticleFinder;
