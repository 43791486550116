import './App.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Blog from "./Blog";
import ArticleFinder from "./ArticleFinder";

function App() {
  return (
    <div className="App">
      <Router>
          <Routes>
              <Route path={"/:id"} element={<ArticleFinder/>} />
              <Route path={"/"} element={<Blog/>}/>
          </Routes>
      </Router>
    </div>
  );
}

export default App;
